import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { useLanguage } from 'gatsby-theme-wordpress/src/context/language';

import ChevronRight from 'gatsby-theme-wordpress/src/components/icons/chevron-right';
import Layout from '../../components/layout';
import Wrap from '../../components/wrap';

export const pageQuery = graphql`
  query YourIndustry($id: String) {
    page: wordpressPage(id: { eq: $id }) {
      yoast_meta {
        name
        content
        property
      }
      yoast_title
      yoast_json_ld {
        wordpress__context
        wordpress__graph {
          wordpress__type
          wordpress__id
          name
          description
          width
          height
          inLanguage
          datePublished
          dateModified
          caption
          isPartOf {
            wordpress__id
          }
          primaryImageOfPage {
            wordpress__id
          }
          url
        }
      }

      fancyheader: featured_media {
        ...Fancyheader
      }
      ...Breadcrumbs
      title
      content
      custom {
        childPages {
          id
          link
          title
        }
      }
    }
  }
`;

const Lists = styled.div`
  display: grid;
  @media screen and (min-width: 990px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 50px;
  }
`;

const StyledLink = styled(Link)`
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #303030;
  text-decoration: none;
`;

const LinkIcon = styled.div`
  background-color: #88b526;
  border-radius: 50%;
  color: white;
  width: 14px;
  height: 14px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${ChevronRight} {
    width: 6px;
  }
`;

const List = styled(({ className, pages }) => (
  <ul className={className}>
    {pages.filter(Boolean).map(({ id, title, link }) => (
      <li key={id}>
        <StyledLink to={link} title={title}>
          {title}
        </StyledLink>
        <LinkIcon>
          <ChevronRight />
        </LinkIcon>
      </li>
    ))}
  </ul>
))`
  padding: 0;
  margin: 0;
  list-style: none;

  &:first-of-type {
    li:first-child {
      border-top: 0;
    }
  }

  @media screen and (min-width: 990px) {
    & {
      li:first-child {
        border-top: 0;
      }
    }
  }

  li {
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid #303030;
    display: flex;
    align-items: center;
    justify-content: left;
  }

  ${StyledLink} {
    order: 2;
  }

  ${LinkIcon} {
    order: 1;
  }
`;

const YourIndustryTemplate = ({ data }) => {
  const { page } = data;
  const language = useLanguage();

  const others = ['Other Industries', 'Andere Branchen'];

  const sortedPages = page.custom?.childPages?.sort((a, b) => a.title.localeCompare(b.title, language, { sensitivity: 'base' }));
  const pages = sortedPages?.filter((p) => !others.includes(p.title))
    .concat(sortedPages.find((p) => others.includes(p.title)));

  if (!pages) {
    return (
      <Layout page={page} />
    );
  }

  const perList = 3;
  const listLength = Math.ceil(pages.length / perList);

  return (
    <Layout page={page}>
      <Wrap>
        <div
          dangerouslySetInnerHTML={{ __html: page.content }}
        />
        <Lists>
          <List pages={pages.slice(0, listLength)} />
          <List pages={pages.slice(listLength, (listLength * 2))} />
          <List pages={pages.slice(listLength * 2)} />
        </Lists>
      </Wrap>
    </Layout>
  );
};

export default YourIndustryTemplate;
